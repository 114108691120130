import admin from 'apis/admin';

import {
  FETCH_ALL_USERS,
  SET_NOTIFICATION_MESSAGE,
  USERS_REQ_IS_LOADING,
  FILTER_USERS_BY_EMAIL,
  ADMIN_ADD_DEPOSIT_SELECTED_COIN,
  CREATE_DEPOSIT,
  FETCH_SEARCHED_USERS,
  ADMIN_REPORTS_REQ_IS_LOADING,
  ADMIN_FETCH_REPORTS,
  ADMIN_TRADES_REQ_IS_LOADING,
  ADMIN_FETCH_TRADES,
  ADMIN_HISTORY_EQUITY_REQ_IS_LOADING,
  ADMIN_FETCH_HISTORY_EQUITY,
  ADMIN_RESET_2FA,
  ADMIN_RESET_2FA_REQ_IS_LOADING,
  ADMIN_USERS_SET_FETCH_USERS_DATE,
  ADMIN_FETCH_MAIN_REPORTS,
  ADMIN_MAIN_REPORTS_REQ_IS_LOADING,
  ADMIN_FETCH_DEPOSITS_WITHDRAWALS,
  ADMIN_DEPOSITS_WITHDRAWALS_REQ_IS_LOADING,
} from 'actions/types';

export const fetchSearchedUser = (emailString, callback) => async (dispatch, getState) => {
  try {
    admin.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: USERS_REQ_IS_LOADING, payload: true });

    const response = await admin.get(`/user/${emailString}`);

    dispatch({ type: FETCH_SEARCHED_USERS, payload: response.data.result })

    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
  }
};

export const fetchAllUsers = () => async (dispatch, getState) => {
  try {
    admin.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: USERS_REQ_IS_LOADING, payload: true });
    const response = await admin.get('/users');

    dispatch({ type: FETCH_ALL_USERS, payload: response.data.result })

    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const fetchUsers = (fromDate, toDate) => async (dispatch, getState) => {
  try {
    admin.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: USERS_REQ_IS_LOADING, payload: true });
    const response = await admin.get('/users', { params: { fromDate, toDate } });

    dispatch({ type: FETCH_ALL_USERS, payload: response.data.result })

    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const createDeposit = (payload, callback) => async (dispatch, getState) => {
  try {
    admin.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: USERS_REQ_IS_LOADING, payload: true });
    const response = await admin.post(`/deposits/create/`, payload);

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: CREATE_DEPOSIT, payload: payload })
    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
    callback();
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.error.message,
      type: 'Error',
      icon: 'error'
    }
    callback();
    dispatch({ type: USERS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const filterUsersByEmail = (email) => {
  return {
    type: FILTER_USERS_BY_EMAIL,
    payload: email
  }
}

export const selectAddDepositCoin = (coinObject) => {
  return {
    type: ADMIN_ADD_DEPOSIT_SELECTED_COIN,
    payload: coinObject
  }
}

export const loadingRequest = (isLoading) => {
  return {
    type: USERS_REQ_IS_LOADING,
    payload: isLoading
  }
}

export const fetchAdminUserReports = (userEmail) => async (dispatch, getState) => {
  admin.defaults.headers = { Authorization: getState().auth.authenticated };

  dispatch({ type: ADMIN_REPORTS_REQ_IS_LOADING, payload: true });
  const response = await admin.get('/reports', { params: { userEmail } });

  dispatch({ type: ADMIN_FETCH_REPORTS, payload: response.data.result })

  dispatch({ type: ADMIN_REPORTS_REQ_IS_LOADING, payload: false });
};

export const fetchAdminUserTrades = (userEmail) => async (dispatch, getState) => {
  try {
    admin.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: ADMIN_TRADES_REQ_IS_LOADING, payload: true });
    const response = await admin.get('/trades', { params: { userEmail } });

    dispatch({ type: ADMIN_FETCH_TRADES, payload: response.data.result })

    dispatch({ type: ADMIN_TRADES_REQ_IS_LOADING, payload: false });
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: ADMIN_TRADES_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};

export const fetchAdminEquityGraphHistory = (userEmail, limit) => async (dispatch, getState) => {
  admin.defaults.headers = { Authorization: getState().auth.authenticated };

  dispatch({ type: ADMIN_HISTORY_EQUITY_REQ_IS_LOADING, payload: true });
  const response = await admin.get(`/history/equity`, { params: { userEmail, limit } });

  dispatch({ type: ADMIN_FETCH_HISTORY_EQUITY, payload: response.data.result })

  dispatch({ type: ADMIN_HISTORY_EQUITY_REQ_IS_LOADING, payload: false });
};

export const fetchDepositsWithdrawals = (userEmail) => async (dispatch, getState) => {
  admin.defaults.headers = { Authorization: getState().auth.authenticated };

  dispatch({ type: ADMIN_DEPOSITS_WITHDRAWALS_REQ_IS_LOADING, payload: true });
  const response = await admin.get(`/history/depositsWithdrawals`, { params: { userEmail } });

  dispatch({ type: ADMIN_FETCH_DEPOSITS_WITHDRAWALS, payload: response.data.result })

  dispatch({ type: ADMIN_DEPOSITS_WITHDRAWALS_REQ_IS_LOADING, payload: false });
};

export const reset2Fa = (userId) => async (dispatch, getState) => {
  try {
    admin.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: ADMIN_RESET_2FA_REQ_IS_LOADING, payload: true });

    const response = await admin.post('/user/reset2fa', { userId });

    dispatch({ type: ADMIN_RESET_2FA, payload: false })

    const notificationPayload = {
      message: response.data.message,
      type: 'Success',
      icon: 'success'
    }

    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });

    dispatch({ type: ADMIN_RESET_2FA_REQ_IS_LOADING, payload: false });
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: ADMIN_RESET_2FA, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
    dispatch({ type: ADMIN_RESET_2FA_REQ_IS_LOADING, payload: false });
  }
};

export const setFetchUsersDates = (startDate, endDate) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_USERS_SET_FETCH_USERS_DATE, payload: { startDate, endDate } });
};

export const fetchMainReportsTrades = (status) => async (dispatch, getState) => {
  try {
    admin.defaults.headers = { Authorization: getState().auth.authenticated };

    dispatch({ type: ADMIN_MAIN_REPORTS_REQ_IS_LOADING, payload: true });
    // TODO: Here should be MainReports Endpoint
    // see: api/v1/trades/?status=....., should have the same format
    const response = await admin.get(`${status ? `/?status=${status}` : ''}`);

    dispatch({ type: ADMIN_FETCH_MAIN_REPORTS, payload: response.data.result })

    dispatch({ type: ADMIN_MAIN_REPORTS_REQ_IS_LOADING, payload: false });
  } catch (err) {
    const notificationPayload = {
      message: err.response.data.message,
      type: 'Error',
      icon: 'error'
    }

    dispatch({ type: ADMIN_MAIN_REPORTS_REQ_IS_LOADING, payload: false });
    dispatch({ type: SET_NOTIFICATION_MESSAGE, payload: notificationPayload });
  }
};