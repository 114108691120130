export const AUTH_USER = 'auth_user';
export const AUTH_VALID_TOKEN = 'auth_valid_token';
export const AUTH_IS_LOADING = 'auth_is_loading';
export const FORGOT_PASSWORD = 'forgot_password';
export const RESET_PASSWORD = 'reset_password';
export const FETCH_USER = 'fetch_user';
export const FETCH_SEARCHED_USERS = 'fetch_searched_users';
export const FINISH_ONBOARDING = 'finish_onboarding';

export const FETCH_EXCHANGES = 'fetch_exchanges';
export const CREATE_EXCHANGE = 'create_exchange';
export const REMOVE_EXCHANGE = 'remove_exchange';
export const EXCHANGES_REQ_IS_LOADING = 'exchange_req_is_loading';

export const FETCH_TRADERS = 'fetch_traders';
export const CREATE_TRADER = 'create_trader';
export const REMOVE_TRADER = 'remove_trader';
export const TRADERS_REQ_IS_LOADING = 'trader_req_is_loading';
export const FETCH_TRADER = 'fetch_trader';
export const UPDATE_TRADER = 'update_trader';

export const FETCH_TRADES = 'fetch_trades';
export const TRADES_REQ_IS_LOADING = 'trade_req_is_loading';

export const FETCH_DEPOSITS = 'fetch_deposits';
export const FETCH_DEPOSIT_ADDRESS = 'fetch_deposit_address';
export const DEPOSITS_REQ_IS_LOADING = 'deposits_req_is_loading';
export const DEPOSITS_ADDRESS_REQ_IS_LOADING = 'deposits_address_req_is_loading';
export const DEPOSITS_SELECTED_COIN = 'deposits_selected_coin';

export const CREATE_WITHDRAWAL = 'create_withdrawal';
export const CANCEL_WITHDRAWAL = 'cancel_withdrawal';
export const FETCH_WITHDRAWALS = 'fetch_withdrawal';
export const FETCH_USERS_WITHDRAWALS = 'fetch_users_withdrawals';
export const FILTER_USER_WITHDRAWALS_BY_ADDRESS = 'filter_user_withdrawals_by_address';
export const WITHDRAWALS_REQ_IS_LOADING = 'withdrawals_req_is_loading';
export const FETCH_WITHDRAWAL_INFO = 'fetch_withdrawal_info';
export const WITHDRAWALS_INFO_REQ_IS_LOADING = 'withdrawals_info_req_is_loading';
export const WITHDRAWALS_SELECTED_COIN = 'withdrawals_selected_coin';
export const FETCH_WITHDRAWAL_ADDRESS = 'fetch_withdrawal_address';
export const CREATE_WITHDRAWAL_ADDRESS = 'create_withdrawal_address';
export const SEND_CONFIRMATION_WITHDRAWAL_ADDRESS = 'send_confirmation_withdrawal_address';
export const CONFIRM_WITHDRAWAL_ADDRESS = 'confirm_withdrawal_address';

export const FETCH_ALL_USERS = 'fetch_all_users';
export const USERS_REQ_IS_LOADING = 'users_req_is_loading';
export const FILTER_USERS_BY_EMAIL = 'filter_users_by_email';
export const FILTER_USERS_BY_WALLET_ADDRESS = 'filter_users_by_wallet_address';
export const ADMIN_ADD_DEPOSIT_SELECTED_COIN = 'admin_add_deposit_selected_coin';
export const CREATE_DEPOSIT = 'create_deposit';

export const SET_NOTIFICATION_MESSAGE = 'set_notification_message';
export const FEES_SELECTED_COIN = 'fees_selected_coin';
export const SET_USER_SELECTED_FEE_CURRENCY = 'set_user_selected_fee_currency';

export const FETCH_REPORTS = 'fetch_reports';
export const FETCH_HISTORY = 'fetch_history';
export const FETCH_HISTORY_EQUITY = 'fetch_history_equity';
export const FETCH_BALANCES = 'fetch_balances';
export const FETCH_BINANCE_BALANCE = 'fetch_binance_balance';
export const REPORTS_REQ_IS_LOADING = 'reports_req_is_loading';
export const HISTORY_EQUITY_REQ_IS_LOADING = 'history_equity_req_is_loading';

export const SET_TWO_FACTOR_SECRET = 'set_two_factor_secret';
export const GET_TWO_FACTOR_SECRET = 'get_two_factor_secret';
export const TWO_FACTOR_REQ_LOADING = 'two_factor_req_loading';

export const CREATE_SIGNATURE_EMBEDDED_LINK = 'create_signature_embedded_link';
export const SIGNATURES_REQ_IS_LOADING = 'signatures_req_is_loading';

export const SET_REFERRAL_CODE = 'set_referral_code';
export const GET_REFERRAL_STATS = 'get_referral_stats';
export const GET_REFERRAL_REWARDS = 'get_referral_rewards';
export const REFERRALS_REQ_IS_LOADING = 'referrals_req_is_loading';

export const SET_TRADING_STATUS = 'set_trading_status';

export const ADMIN_FETCH_REPORTS = 'admin_fetch_reports';
export const ADMIN_REPORTS_REQ_IS_LOADING = 'admin_reports_req_is_loading';
export const ADMIN_FETCH_TRADES = 'admin_fetch_trades';
export const ADMIN_TRADES_REQ_IS_LOADING = 'admin_trades_req_is_loading';
export const ADMIN_FETCH_HISTORY_EQUITY = 'admin_fetch_history_equity';
export const ADMIN_HISTORY_EQUITY_REQ_IS_LOADING = 'admin_history_equity_req_is_loading';
export const ADMIN_FETCH_DEPOSITS_WITHDRAWALS = 'admin_fetch_deposits_withdrawals';
export const ADMIN_DEPOSITS_WITHDRAWALS_REQ_IS_LOADING = 'admin_deposits_withdrawals_req_is_loading';

export const ADMIN_RESET_2FA = 'admin_reset_2fa';
export const ADMIN_RESET_2FA_REQ_IS_LOADING = 'admin_reset_2fa_req_is_loading';
export const ADMIN_USERS_SET_FETCH_USERS_DATE = 'admin_users_set_fetch_users_date';
export const ADMIN_FETCH_MAIN_REPORTS = 'admin_fetch_main_reports';
export const ADMIN_MAIN_REPORTS_REQ_IS_LOADING = 'admin_main_reports_req_is_loading';