import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import requireAuth from 'components/requireAuth';
import { Row, Table } from 'antd';
import moment from 'moment';
import Scrollbar from 'react-smooth-scrollbar';
import AdminFetchReportsForm from 'components/shared/AdminFetchReportsForm';
import Loader from "components/shared/Loader";
import EquityGraph from 'components/shared/Statistics/equityGraph';
import StrikeRate from 'components/shared/Statistics/strikeRate';
import ReportPie from 'components/shared/Statistics/reportPie';
import TradeHistory from 'components/shared/Statistics/tradeHistory';
import exist from 'exist.js';
import { Icon } from 'antd';
import { fetchAdminUserReports, fetchAdminUserTrades, fetchAdminEquityGraphHistory, fetchDepositsWithdrawals } from 'actions/admin';

class AdminReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: this.props.searchedUsersList[0] || ''
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderUserReports()}
      </React.Fragment>
    )
  }

  fetchUserReports = (form, e) => {
    e.preventDefault();

    form.validateFields((error, values) => {
      if (error) {
        console.log(error);
      } else {

        this.setState({
          userEmail: values.userEmail
        })

        this.props.fetchAdminUserReports(values.userEmail);
        this.props.fetchAdminUserTrades(values.userEmail);
        this.props.fetchAdminEquityGraphHistory(values.userEmail, 9999);
        this.props.fetchDepositsWithdrawals(values.userEmail);
      }
    });
  }

  componentWillUnmount() {
    this.props.fetchAdminUserReports('');
  }

  renderUserReports() {
    return (
      <Row className="fade-in">
        <AdminFetchReportsForm onSubmit={this.fetchUserReports}/>

        {this.props.adminReportsRequestLoading
        ?
        <div className="mt-56"><Loader/></div>
        :
        <React.Fragment>
          {this.renderReportsPageContent()}
          {this.renderDepositsPageContent()}
        </React.Fragment>
        }

      </Row>
    )
  }

  renderReportsPageContent() {
    const { reportsStats, equityHistory } = this.props;

    const pieChartDataExists = exist.get(reportsStats, 'pieChartData');

    if (pieChartDataExists && pieChartDataExists.length > 0) {
      return (
        <>
          <EquityGraph history={equityHistory} fetchEquityHistory={this.props.fetchAdminEquityGraphHistory} userEmail={this.state.userEmail}/>
          <div className="page-wrapper trades-page-wrapper reports-widget-wrapper mt-20">
            <div className="reports-widget portfolio-widget">
              <div className="report-widget-body">
                <ReportPie reports={reportsStats}/>
              </div>
            </div>

            <div className="reports-widget strike-widget">
              <StrikeRate reports={reportsStats}/>
            </div>

            <div className="reports-widget history-widget">
              <div className="">
                {this.renderTradesContent()}
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <div className="text-center mt-48">
          <Icon type="info-circle" style={{fontSize: "54px", color: "#e8e8e8"}} />

          <div style={{ marginTop: "10px", color: "#00000040" }}>
            Not enough data available
          </div>
        </div>
      )
    }
  };

  renderTradesContent() {
    if (this.props.adminTradesRequestLoading) {
      return (
        <Loader/>
      )
    } else {
      return (
        <React.Fragment>
          <TradeHistory trades={this.props.trades}/>
        </React.Fragment>
      )
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderStatusButton(status) {
    let capitalizedStatus;

    if (status) {
      capitalizedStatus = this.capitalizeFirstLetter(status);
    }

    switch(status) {
      case "Pending":
        return <div className="btn--status in-progress">{capitalizedStatus}</div>
      case "Complete":
      case "Deposit confirmed":
      case "Converted from BTC":
        return <div className="btn--status completed">{capitalizedStatus}</div>
      case "Cancelled":
        return <div className="btn--status cancelled">{capitalizedStatus}</div>
      default:
        return <div className="btn--status in-progress">{capitalizedStatus}</div>
    }
  }

  renderAmountElement = (size, currency) => {
    return (
      <div className="flex">
        {size}
        <div className="ml-2 font-semibold">{currency}</div>
      </div>
    )
  }

  renderTransactionElement = (transactionId, currency) => {
    if (currency == 'ETH' || currency == 'USDT' || currency == 'CYL') {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`https://etherscan.io/tx/${transactionId}`} className="text-crystal-primary">
          view transaction <span style={{fontSize: "10px", marginLeft: "2px"}}>↗</span>
        </a>
      )
    } else if (currency == 'BTC') {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`https://blockchain.info/tx/${transactionId}`} className="text-crystal-primary">
          view transaction <span style={{fontSize: "10px", marginLeft: "2px"}}>↗</span>
        </a>
      )
    }
  }

  renderDepositsTable() {
    const columns = [
      {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? moment(value).format('ll HH:mm') : '-'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (value, record) => value ? this.renderAmountElement(value, record.currency) : '-'
      },
      {
        title: 'Blockchain Record',
        dataIndex: 'transactionId',
        key: 'transactionId',
        render: (value, record) => value ? this.renderTransactionElement(value, record.transactionType || record.currency) : '-'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => this.renderStatusButton(status)
      },
    ];

    return (
      <Row>
        <h3 className="title-underline font-semibold">Deposits</h3>
        <Scrollbar>
          <Table
            rowClassName="table-row"
            dataSource={this.props.depositsWithdrawalsHistory.depositList}
            columns={columns}
          />
        </Scrollbar>
      </Row>
    )
  }

  renderWithdrawalsTable() {
    const columns = [
      {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: (a, b) => b.createdAt < a.createdAt,
        render: value => value ? moment(value).format('ll HH:mm') : '-'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (value, record) => value ? this.renderAmountElement(value, record.currency) : '-'
      },
      {
        title: 'Wallet Address',
        dataIndex: 'address',
        key: 'address',
        render: value => value ? value : '-'
      },
      {
        title: 'Blockchain Record',
        dataIndex: 'transactionId',
        key: 'transactionId',
        render: (value, record) => value ? this.renderTransactionElement(value, record.currency) : '-'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => this.renderStatusButton(status)
      }
    ];

    return (
      <Row>
        <h3 className="title-underline font-semibold">Withdrawals</h3>
        <Scrollbar>
          <Table
            rowClassName="table-row"
            dataSource={this.props.depositsWithdrawalsHistory.withdrawalList}
            columns={columns}
          />
        </Scrollbar>
      </Row>
    )
  }

  renderDepositsPageContent() {
    if (this.props.depositsWithdrawalsHistory.depositList.length || this.props.depositsWithdrawalsHistory.withdrawalList.length) {
      return (
        <React.Fragment>
          {this.renderDepositsTable()}
          {this.renderWithdrawalsTable()}
        </React.Fragment>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    trades: Object.values(state.admin.adminTradesList),
    searchedUsersList: state.admin.searchedUsersList,
    adminReportsRequestLoading: state.admin.adminReportsRequestLoading,
    adminTradesRequestLoading: state.admin.adminTradesRequestLoading,
    reportsStats: state.admin.stats,
    equityHistory: state.admin.equityHistory,
    depositsWithdrawalsHistory: state.admin.depositsWithdrawalsHistory,
    depositsWithdrawalsRequestLoading: state.admin.depositsWithdrawalsRequestLoading,
  }
};

export default compose(
  requireAuth,
  connect(mapStateToProps, { fetchAdminEquityGraphHistory, fetchAdminUserReports, fetchAdminUserTrades, fetchDepositsWithdrawals }),
)(AdminReports);
